<template>
	<Row
		:gutter="32"
		type="flex"
		justify="center"
		align="middle"
		class="text-consult-wrap"
	>
		<Col :xs="18" :lg="12">
			<p class="text">
				<span v-if="$route.query.env === 'shyfs'"
					>您的申请已提交，请按时到 眼病防治中心四号楼2楼会议室！</span
				>
				<span v-else>您的咨询申请已提交，感谢您的配合！</span>
			</p>
		</Col>
	</Row>
</template>

<script>
export default {
	name: "fullFillIn",
	data() {
		return {}
	},
	created() {},
	methods: {}
}
</script>
<style lang="less">
body {
	min-width: unset;
}
.text-consult-wrap {
	height: 80%;
	.ivu-col {
		box-shadow: 0 0 15px #eee;
		border-radius: 15px;
		background: #f5b65c;
	}
	.text {
		display: flex;
		justify-content: center;
		align-items: center;
		height: 200px;
		font-size: 26px;
		text-align: center;
		border-radius: 5px;
		color: #fff;
	}
}
</style>

<template>
	<div class="content-wrap">
		<!-- 月入组统计报表 -->
		<Spin fix v-if="loading"></Spin>
		<ul class="page_text_num">
			<li>本月入组总例数：<span>{{ currentMonthNumber }}</span></li>
			<li>本年度IST项目入组总例数：<span>{{ currentYearISTNumber }}</span></li>
			<li>本年度IIT项目入组总例数：<span>{{ currentYearIITNumber }}</span></li>
		</ul>
      <CtmsSearchBox class="search_left" @onSearch="searchHandle" @onReset="reset">
		<CtmsSearchBoxItem :span="1" label="项目名称">
			<Input placeholder="请输入名称" v-model="searchParams.projectName" />
			</CtmsSearchBoxItem>
        <CtmsSearchBoxItem :span="1" label="入组时间">
          <DatePicker
            type="daterange"
            placeholder="请选择入组时间"
            :transfer="true"
            style="width: 100%"
            format="yyyy-MM-dd"
            :value="times"
            @on-change="v => (times = v)"
          ></DatePicker>
        </CtmsSearchBoxItem>
        <CtmsSearchBoxItem :span="1" label="项目类型">
          <Select
            v-model="searchParams.projectConfigId"
            placeholder="请选择项目类型"
            :transfer="true"
            clearable
          >
            <Option
              v-for="item in projTypeList"
              :value="item.id"
              :key="item.id"
            >{{ item.typeName }}</Option
            >
          </Select>
        </CtmsSearchBoxItem>
			<CtmsSearchBoxItem :span="1" label="研究型病房项目">
				<Select
					v-model="searchParams.trialResearchWard"
					:transfer="true"
					placeholder="请选择研究型病房项目"
				>
				<Option  value="是" label="是"></Option>
				<Option value="否" label="否"></Option>
				</Select>
			</CtmsSearchBoxItem>
<!--			<CtmsSearchBoxItem :span="1" label="当月入组例数大于0">-->
<!--				<Select-->
<!--					v-model="searchParams.currentMonthGroupPositiveFlag"-->
<!--					:transfer="true"-->
<!--					placeholder="请选择"-->
<!--				>-->
<!--				<Option  value="1" label="是"></Option>-->
<!--				<Option value="0" label="否"></Option>-->
<!--				</Select>-->
<!--			</CtmsSearchBoxItem>-->


      </CtmsSearchBox>

		<CtmsAction>
			<ul class="page_text_num custom_text_num">
<!--				<li><Button @click="exportData">导出历年入组情况</Button></li>-->
				<li>查询期间总入组例数：<span>{{ searchCurrentMonthNumber }}</span></li>
				<li>IST项目入组总例数：<span>{{searchCurrentYearISTNumber }}</span></li>
				<li>IT项目入组总例数：<span>{{ searchCurrentYearIITNumber }}</span></li>
			</ul>
		</CtmsAction>
		<CtmsDataGrid
			:height="dataGridHeight"
			:columns="columns"
			:data="data"
      :page="page"
			ref="table"
		>
		</CtmsDataGrid>
	</div>
</template>

<script>
import api from "@/api/project/project"
import reportApi from "@/api/report/report"
import { mapState } from "vuex"
const {
  apiGetProjTypeList,
} = api
const { apiGetGroupNumber, apiGetPageReportList } = reportApi

export default {
	name: "WardCheckIn",
	data() {
		return {
      projTypeList: [], // 项目类型列表
			currentMonthNumber: '--',
			currentYearIITNumber: '--',
			currentYearISTNumber: '--',
			searchCurrentMonthNumber: '--',
			searchCurrentYearIITNumber: '--',
			searchCurrentYearISTNumber: '--',

			loading: false,
			entry: "",
			updateId: "",
			fundsType: "", // 1 入账 / 2 出账
			// 科室
			departList: [],
			// 项目基本信息
			projectInfo: {},
			times: [],
			searchParams: {
        projectName: "",
        projectConfigId: "",
        trialResearchWard: "",
        currentMonthGroupPositiveFlag: "1",
				startTime: this.times ? this.times[0] : "",
				endTime: this.times ? this.times[1] : ""
			},
			oldSearchParams: {
        projectName: "",
        projectConfigId: "",
        trialResearchWard: "",
        currentMonthGroupPositiveFlag: "1",
				startTime: this.times ? this.times[0] : "",
				endTime: this.times ? this.times[1] : ""
			},
			data: [],
			columns: [
				{
					title: "项目名称",
					key: "projectName",
					minWidth: 250
				},
				{
					title: "项目类型",
					key: "projectType",
					minWidth: 140,
				},
				{
					key: "projectCurrentMonthGroupNumber",
					minWidth: 140,
          renderHeader: (h, params) => {
            return h('div', [
              '入组例数',
              h('Icon', {
                attrs: {
                  title: '1.查询单月时本数据为查询月入组例数;\n 2.查询多月时本数据为查询期问共计入组例数',
                },
                props: {
                  type: 'ios-information-circle',
                },
                style: {
                  color: '#06f !important',
                  fontSize: '16px',
                  marginLeft: '5px',
                  cursor: 'pointer'
                },
              }),
            ])
          }
				},
				{
					title: "项目累计入组例数",
					key: "projectGroupNumber",
					minWidth: 140
				}
			],
			page: {
				current: 1,
				pageSize: 10,
				on: {
					onChange: this.onChange,
					onPageSizeChange: this.onPageSizeChange
				},
				total: 0
			}
		}
	},
	computed: {
		...mapState({
			dataGridHeight: state => state.contentHeight - 140
		})
	},
  created() {
    this.getProjType()
  },
  mounted() {
		this.getGroupNumber()
		this.initData()
	},
	methods: {
    async getProjType() {
      // 获取 新增项目类型 列表
      const res = await apiGetProjTypeList({ isDesigned: 1 }) // 只显示已配置过表单的项目类型。
      if (res) {
        this.projTypeList = res.data || []
      }
    },
		getGroupNumber(data = {}) {
			// 获取科室
			this.$asyncDo(async () => {
				const res = await apiGetGroupNumber(data)
				if (res) {
					const { currentMonthNumber, currentYearIITNumber, currentYearISTNumber } = res.data
					this.currentMonthNumber = currentMonthNumber || currentMonthNumber === 0 ? currentMonthNumber : '--'
					this.currentYearIITNumber = currentYearIITNumber || currentYearIITNumber === 0 ? currentYearIITNumber : '--'
					this.currentYearISTNumber = currentYearISTNumber || currentYearISTNumber === 0 ? currentYearISTNumber : '--'
				}
			})
		},
		initData() {
			this.$asyncDo(async () => {
				this.loading = true
				const start = this.searchParams.startTime || ""
				const end = this.searchParams.endTime || ""
				if(start && end){
					const res = await apiGetGroupNumber({
						startTime: start,
						endTime: end
					})
					if (res) {
						const { currentMonthNumber, currentYearIITNumber, currentYearISTNumber } = res.data
						this.searchCurrentMonthNumber = currentMonthNumber || currentMonthNumber === 0 ? currentMonthNumber : '--'
						this.searchCurrentYearIITNumber = currentYearIITNumber || currentYearIITNumber === 0 ? currentYearIITNumber : '--'
						this.searchCurrentYearISTNumber = currentYearISTNumber || currentYearISTNumber === 0 ? currentYearISTNumber : '--'
					}
				}
				this.loading = false
			})
			this.$asyncDo(async () => {
				this.loading = true
				const res = await apiGetPageReportList({
					...this.oldSearchParams,
					pageNum: this.page.current,
					pageSize: this.page.pageSize,
				})
				if (res) {
					this.data = res.data || []
					this.page.total = res.totalCount
				}
				this.loading = false
			})
		},
		// exportData() {
		// 	/*
		// 	 * type 费用类型：IST、申办方发起的研究IST IIT、研究者发起的研究IIT
		// 	 * startTime 创建时间-开始
		// 	 * endTime 创建时间-结束
		// 	 * */
		// 	const projectConfigId = this.searchParams.projectConfigId || ""
		// 	const trialResearchWard = this.searchParams.trialResearchWard || "1"
		// 	const currentMonthGroupPositiveFlag = this.searchParams.currentMonthGroupPositiveFlag || ""
		// 	const start = this.searchParams.startTime || ""
		// 	const end = this.searchParams.endTime || ""
		// 	const projectName =
		// 		this.searchParams.projectName === undefined
		// 			? ""
		// 			: this.searchParams.projectName
		// 	const url = `${this.$baseUrl}/report/outlay/export?classification=1&type=${type}&startTime=${start}&endTime=${end}&approvedStatus=${approvedStatus}&token=${this.$store.state.user.token}`
		// 	window.open(url)
		// },
		// 重置
		reset() {
			this.times = []
			this.searchParams.projectConfigId = ""
			this.searchParams.trialResearchWard = ""
			this.searchParams.currentMonthGroupPositiveFlag = "1"
			this.searchParams.projectName = ""
			this.searchHandle()
		},
		// 页面改变
		onChange(current) {
			this.page.current = current
			this.$nextTick(() => {
				this.initData()
			})
		},
		// 页面显示数量改变
		onPageSizeChange(pageSize) {
			this.page.pageSize = pageSize
			this.$nextTick(() => {
				this.initData()
			})
		},
		searchHandle() {
			this.searchParams.startTime = this.times.length
				? this.times[0]
				: ""
			this.searchParams.endTime = this.times.length ? this.times[1] : ""
			this.oldSearchParams = Object.assign({}, this.searchParams)
			this.page.current = 1
			this.$nextTick(() => {
				this.initData()
			})
		}
	}
}
</script>
<style lang="less" scoped>
    .page_text_num{
      display: flex;
      flex-direction: row;
	  justify-content: space-evenly;
	  align-items: center;
	  font-size: 14px;
	  li{
		list-style: none;
		width: 33%;
		span {
			vertical-align: middle;
			font-weight: bold;
			font-size: 20px;
			color: #4863f3;
		}
	}
  	}
	.custom_text_num{
		li{
			width: 25%;
			span {
				color: #cf6863;
			}
		}
	}
</style>
